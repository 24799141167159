export default [
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:200",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "max:200",
    type: "text",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer",
    type: "text",
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
